import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Tabs from 'antd/es/tabs';

import layout from './layout';
import costs from './costs';
import ssh from './ssh';

export namespace tabs {
    export function Index() {
        const history = useHistory();
        const match = useRouteMatch<{ tabId?: string }>();
        const tabId = match.params.tabId ?? 'cloud-costs';

        return (
            <Tabs
                activeKey={tabId}
                onChange={(nextTabId) => {
                    history.push(`/${nextTabId}`);
                }}
                size="large">
                <Tabs.TabPane tab="Cloud Costs" key="cloud-costs">
                    <layout.Padded>
                        <costs.CloudCosts />
                    </layout.Padded>
                </Tabs.TabPane>
                <Tabs.TabPane tab="SSH Keys" key="ssh">
                    <layout.Padded>
                        <ssh.Keys />
                    </layout.Padded>
                </Tabs.TabPane>
            </Tabs>
        );
    }
}

export default tabs;
