import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';
import { Theme } from '@allenai/varnish/es/theme';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import auth from '../auth';
import api from '../api';
import context from '../context';
import loading from './loading';
import text from './text';

dayjs.extend(utc);

export namespace costs {
    const Container = styled.div`
        height: 600px;
    `;

    function formatValue(v: number) {
        const nf = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        return nf.format(v);
    }

    function formatDate(d: Date) {
        return dayjs(d).format('MM-DD');
    }

    function formatDateWithYear(d: Date) {
        return dayjs(d).format('YYYY-MM-DD');
    }

    function prepareData(charges: api.ChargesByProvider) {
        const chargesByProviderByDay: { [p: string]: { [d: string]: number } } = {};
        for (const group of charges.groups) {
            if (!(group.provider in chargesByProviderByDay)) {
                chargesByProviderByDay[group.provider] = {};
            }
            for (const interval of group.intervals) {
                const day = formatDate(interval.start);
                if (day in chargesByProviderByDay[group.provider]) {
                    throw new Error(`already have charges for ${day} for ${group.provider}`);
                }
                chargesByProviderByDay[group.provider][day] = interval.value;
            }
        }

        const providers = Object.keys(chargesByProviderByDay);
        const current = new Date(charges.total.start);
        const endMonth = dayjs(current).utc().add(1, 'month').month();
        const data = [];
        while (current.getUTCMonth() !== endMonth) {
            const day = formatDate(current);
            const entry: { [k: string]: string | number } = { day };
            for (const p of providers) {
                entry[p] = chargesByProviderByDay[p][day] ?? 0;
            }
            data.push(entry);
            current.setDate(current.getDate() + 1);
        }
        return { data, keys: providers };
    }

    export function CloudCosts() {
        const [cloudCosts, setCloudCosts] = useState<api.ChargesByProvider>();
        const [err, setErr] = useState(false);
        const [startOfMonth, setStartOfMonth] = useState<string>();
        const { user } = useContext(context.LoggedInUser);
        if (!user) {
            auth.Login();
            return null;
        }

        useEffect(() => {
            const start = new Date();
            start.setDate(1);
            setStartOfMonth(formatDateWithYear(start));
            api.GetCloudCosts(user.username, start)
                .then((cc) => {
                    setCloudCosts(cc);
                })
                .catch((err) => {
                    console.error(`fetching cloud costs: `, err);
                    if (api.IsUnauthorized(err)) {
                        auth.RefreshExpiredLogin();
                    }
                    setErr(true);
                });
        }, []);

        if (!err && !cloudCosts) {
            return (
                <Container>
                    <loading.Centered>
                        <loading.FakeProgressSpinner />
                    </loading.Centered>
                </Container>
            );
        }

        if (err || !cloudCosts) {
            return (
                <Container>
                    <loading.Centered>
                        <loading.Error />
                    </loading.Centered>
                </Container>
            );
        }

        const { data, keys } = prepareData(cloudCosts);
        const colors = Object.values(Theme.lightCategoricalColor).map((c) => c.hex);

        return (
            <Container>
                <text.Big>
                    <strong>You've spent {formatValue(cloudCosts.total.value)} this month.</strong>
                    <br />
                    See your costs in{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://milton.apps.allenai.org/?group=provider&contact=${user.username}&start=${startOfMonth}`}>
                        Milton
                    </a>
                </text.Big>
                <ResponsiveBar
                    data={data}
                    keys={keys}
                    indexBy="day"
                    margin={{ top: 75, right: 75, left: 75, bottom: 75 }}
                    padding={0.1}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 10,
                        tickRotation: 45,
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 5,
                        format: formatValue,
                    }}
                    groupMode="stacked"
                    colors={colors}
                    valueFormat={formatValue}
                    enableLabel={false}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'top-right',
                            direction: 'row',
                            translateY: -40,
                            justify: false,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            symbolSize: 20,
                        },
                    ]}
                />
            </Container>
        );
    }
}

export default costs;
