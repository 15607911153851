import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish';

import api from './api';
import auth from './auth';
import context from './context';
import loading from './components/loading';
import logo from './components/logo';
import user from './components/user';
import tabs from './components/tabs';
import message from './components/message';

export const App = () => {
    const [loggedInUser, setLoggedInUser] = useState<api.User>();

    useEffect(() => {
        api.GetLoggedInUser().then((u) => {
            if (u) {
                setLoggedInUser(u);
            } else {
                auth.Login();
            }
        });
    }, []);

    if (!loggedInUser) {
        return (
            <loading.FullPage>
                <loading.FakeProgressSpinner />
            </loading.FullPage>
        );
    }

    return (
        <context.LoggedInUser.Provider value={{ user: loggedInUser }}>
            <BrowserRouter>
                <Route path="/">
                    <Layout bgcolor="white">
                        <Header>
                            <Header.Columns columns="auto 1fr auto">
                                <Header.Logo label={<Header.AppName>Bridge</Header.AppName>}>
                                    <logo.BridgeLogo />
                                </Header.Logo>
                                <user.Column>
                                    <user.LoggedInUserInfo user={loggedInUser} />
                                </user.Column>
                            </Header.Columns>
                        </Header>
                        <Content main>
                            <message.Welcome />
                            <BrowserRouter>
                                <Route path="/:tabId" exact>
                                    <tabs.Index />
                                </Route>
                                <Route path="/" exact>
                                    <Redirect to="/cloud-costs" />
                                </Route>
                            </BrowserRouter>
                        </Content>
                        <Footer />
                    </Layout>
                </Route>
            </BrowserRouter>
        </context.LoggedInUser.Provider>
    );
};
