import React from 'react';
import styled from 'styled-components';
import Button from 'antd/es/button';

export namespace quicklinks {
    const UL = styled.ul`
        ${({ theme }) => `
            display: flex;
            gap: ${theme.spacing.sm};
            align-items: center;
            list-style-type: none;
            margin: 0;
            padding: 0;
        `}
    `;

    export function List() {
        const links = [
            {
                href: 'https://allenai.1password.com/teamjoin/invitation/CAKUNVB5WNB3RGKI2NZPVULYZQ',
                text: 'Sign up for 1Password',
            },
            {
                href: 'https://docs.google.com/document/d/1oRftw8YvXOeUwPTj8PFr0fWX7Ualh48vSnY3qOPwjrk',
                text: 'VPN Documentation',
            },
            {
                href: 'https://intranet.allenai.org',
                text: 'AI2 Intranet',
            },
            {
                href: 'https://sites.google.com/allenai.org/intranet/departments/infrastructure/it#h.5kc1a7rjgytu',
                text: 'Frequently Asked Questions',
            },
        ];
        return (
            <UL>
                <li>
                    <strong>Quick Links:</strong>
                </li>
                {links.map((l) => (
                    <li key={l.href}>
                        <Button type="link" href={l.href} rel="noreferrer,noopener">
                            {l.text}
                        </Button>
                    </li>
                ))}
            </UL>
        );
    }
}

export default quicklinks;
