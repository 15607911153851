import message from 'antd/es/message';

export namespace auth {
    export function Login() {
        document.location = `${document.location.origin}/api/login`;
    }

    export const ExpiredSessionMessage =
        "Your session has expired. The page will be refreshed shortly, and you'll be asked to log in again.";

    export function RefreshExpiredLogin(preamble: string = '', delayMs: number = 2000) {
        message.error(
            `${preamble} Your session has expired. The page will be reloaded shortly, ` +
                "and you'll be asked to log in again."
        );
        setTimeout(() => {
            document.location.reload();
        }, delayMs);
    }
}

export default auth;
