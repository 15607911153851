import React from 'react';

import card from './card';
import text from './text';
import quicklinks from './quicklinks';

export namespace message {
    export function Welcome() {
        return (
            <card.Info>
                <text.Jumbo>
                    <strong>Welcome to Bridge</strong>
                </text.Jumbo>
                <text.Big>
                    Bridge is a self-service portal for AI2's internal infrastructure. It allows you
                    to see cloud costs, update your SSH keys used to access AI2 machines, and more.
                </text.Big>
                <quicklinks.List />
            </card.Info>
        );
    }
}

export default message;
