import React from 'react';
import styled from 'styled-components';

export namespace card {
    const InfoBackground = styled.div`
        ${({ theme }) => `
            padding: ${theme.spacing.lg};
            background: ${theme.palette.background.info};
            border-bottom: 4px solid ${theme.palette.border.info};
            margin: 0 0 ${theme.spacing.lg};
            display: grid;
            gap: ${theme.spacing.sm};
        `}
    `;

    export function Info({ children }: { children: React.ReactNode }) {
        return <InfoBackground>{children}</InfoBackground>;
    }
}

export default card;
