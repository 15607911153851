import styled from 'styled-components';

export namespace layout {
    export const Padded = styled.div`
        ${({ theme }) => `
            padding: ${theme.spacing.sm} ${theme.spacing.lg};
        `}
    `;
}

export default layout;
