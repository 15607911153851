import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Progress from 'antd/es/progress';

import text from './text';

export namespace loading {
    export const FullPage = styled.div`
        ${({ theme }) => `
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing.lg};
        `}
    `;

    export const Centered = styled.div`
        ${({ theme }) => `
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing.lg};
            height: 100%;
        `}
    `;

    export const FakeProgressSpinner = () => {
        const [percent, setPercent] = useState(0);

        useEffect(() => {
            const t = setTimeout(() => {
                const delta = (100 - percent) * Math.random();
                const p = Math.max(0, Math.ceil(percent + delta));
                setPercent(p);
            }, 150);
            return () => {
                clearTimeout(t);
            };
        }, [percent]);

        return <Progress type="circle" percent={percent} />;
    };

    const Message = styled.div`
        ${({ theme }) => `
            display: grid;
            gap: ${theme.spacing.md};
            text-align: center;

            > .ant-progress {
                display: grid;
                justify-content: center;
            }
        `}
    `;

    export const Error = () => (
        <Message>
            <Progress type="circle" status="exception" percent={100} />
            <text.Big>
                <strong>Something went wrong.</strong>
                <br />
                If this keeps happening, please{' '}
                <a href="mailto:infrastructure@allenai.org">contact us</a>.
            </text.Big>
        </Message>
    );
}
export default loading;
