import { createContext } from 'react';

import api from './api';

export namespace context {
    interface LoggedInUserStore {
        user?: api.User;
    }

    export const LoggedInUser = createContext<LoggedInUserStore>({ user: undefined });
}

export default context;
