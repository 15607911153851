import axios from 'axios';

export namespace api {
    export function IsUnauthorized(err: any) {
        // Semantically this actually means "not authenticated". See explanation here:
        // https://stackoverflow.com/questions/3297048/403-forbidden-vs-401-unauthorized-http-responses
        return axios.isAxiosError(err) && err.response?.status === 401;
    }

    export class User {
        constructor(
            readonly id: string,
            readonly name: string,
            readonly email: string,
            readonly picture: string,
            readonly username: string
        ) {}
    }

    export async function GetLoggedInUser() {
        try {
            const r = await axios.get<User>('/api/whoami');
            return r.data;
        } catch (err) {
            console.error(`authenticating: ${err}`);
            return null;
        }
    }

    // These structs capture the expected shape of the data after calling
    // JSON.parse().
    namespace raw {
        export interface Charge {
            readonly start: string;
            readonly end: string;
            readonly value: string;
        }

        export interface ProviderCharges {
            readonly provider: string;
            readonly total: Charge;
            readonly intervals: Charge[];
        }

        export interface ChargesByProvider {
            readonly total: Charge;
            readonly groups: ProviderCharges[];
        }

        export interface PublicSSHKey {
            readonly fingerprint: string;
            readonly key: string;
            readonly name: string;
            readonly expiration_time_usec: number;
            readonly retain_until_time_usec: number;
        }
    }

    export class Charge {
        constructor(readonly start: Date, readonly end: Date, readonly value: number) {}

        static fromJSON(payload: raw.Charge) {
            return new Charge(
                new Date(payload.start),
                new Date(payload.end),
                parseFloat(payload.value)
            );
        }
    }

    export class ProviderCharges {
        constructor(
            readonly provider: string,
            readonly total: Charge,
            readonly intervals: Charge[]
        ) {}

        static fromJSON(payload: raw.ProviderCharges) {
            return new ProviderCharges(
                payload.provider,
                Charge.fromJSON(payload.total),
                payload.intervals.map(Charge.fromJSON)
            );
        }
    }

    export class ChargesByProvider {
        constructor(readonly total: Charge, readonly groups: ProviderCharges[]) {}

        static fromJSON(payload: raw.ChargesByProvider) {
            return new ChargesByProvider(
                Charge.fromJSON(payload.total),
                payload.groups.map(ProviderCharges.fromJSON)
            );
        }
    }

    export async function GetCloudCosts(username: string, start: Date) {
        const u = new URL(document.location.toString());
        u.pathname = `/api/user/${username}/cloud-costs`;
        u.searchParams.set(
            'start',
            `${start.getUTCFullYear()}-${start.getUTCMonth() + 1}-${start.getUTCDate()}`
        );

        const r = await axios.get<raw.ChargesByProvider>(u.toString());
        return ChargesByProvider.fromJSON(r.data);
    }

    export class PublicSSHKey {
        constructor(
            readonly fingerprint: string,
            readonly name: string,
            readonly publicKey: string,
            readonly expirationTimeUsec: number,
            readonly retainUntilTimeUsec: number
        ) {}

        static fromJSON(payload: raw.PublicSSHKey) {
            return new PublicSSHKey(
                payload.fingerprint,
                payload.name,
                payload.key,
                payload.expiration_time_usec,
                payload.retain_until_time_usec
            );
        }
    }

    export async function ListPublicSSHKeys(username: string, expired: boolean = true) {
        const r = await axios.get<raw.PublicSSHKey[]>(
            `/api/user/${username}/keys`,
            expired ? { params: { expired: true } } : {}
        );
        return r.data.map((k) => PublicSSHKey.fromJSON(k));
    }

    export async function AddPublicSSHKey(username: string, key: string) {
        return axios.post(`/api/user/${username}/key`, { public_key: key });
    }

    export async function DeletePublicSSHKey(username: string, fingerprint: string) {
        return axios.delete(`/api/user/${username}/key/${fingerprint}`);
    }

    export async function RenewPublicSSHKey(username: string, fingerprint: string) {
        return axios.post(`/api/user/${username}/key/${fingerprint}/renew`);
    }
}

export default api;
