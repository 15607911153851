import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { VarnishApp } from '@allenai/varnish';
import { ScrollToTopOnPageChange } from '@allenai/varnish-react-router';

import '@allenai/varnish/theme.css';

import { App } from './App';

const VarnishedApp = () => (
    <BrowserRouter>
        <ScrollToTopOnPageChange />
        <VarnishApp>
            <Route path="/" component={App} />
        </VarnishApp>
    </BrowserRouter>
);

ReactDOM.render(<VarnishedApp />, document.getElementById('root'));
