import React from 'react';
import styled from 'styled-components';
import * as commonmark from 'commonmark';
import { textStyles } from '@allenai/varnish/es/components/textStyles';

export namespace text {
    export const Jumbo = textStyles.Jumbo;
    export const Big = textStyles.Big;

    const Mkdown = styled.div`
        ${({ theme }) => `
            h4 {
                margin: ${theme.spacing.xl} 0 0;
            }

            p {
                margin: 0 0 ${theme.spacing.sm};
            }

            ol, ul {
                margin: ${theme.spacing.sm};
            }

            pre {
                margin: 0;
            }

            pre > code {
                display: block;
                padding: ${theme.spacing.xs2};
            }

            pre {
                margin: ${theme.spacing.sm} 0;
            }
        `}
    `;

    interface MarkdownProps {
        className?: string;
        children: string;
    }

    export function Markdown({ className, children }: MarkdownProps) {
        const reader = new commonmark.Parser({ smart: true });
        const parsed = reader.parse(children);

        const writer = new commonmark.HtmlRenderer({ safe: true });
        const __html = writer.render(parsed);

        return <Mkdown className={className} dangerouslySetInnerHTML={{ __html }} />;
    }
}

export default text;
