import React from 'react';
import styled from 'styled-components';

export namespace logo {
    const Background = styled.div`
        ${({ theme }) => `
            border-radius: 25px;
            width: 50px;
            height: 50px;
            line-height: 1;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${theme.palette.background.light};
            border: 2px solid ${theme.palette.border.dark};
        `}
    `;

    export function BridgeLogo() {
        return (
            <Background>
                <span role="img" aria-label="Bridge Logo">
                    ⛴
                </span>
            </Background>
        );
    }
}

export default logo;
