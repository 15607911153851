import React from 'react';
import styled from 'styled-components';

import api from '../api';

export namespace user {
    const UserInfo = styled.span`
        ${({ theme }) => `
            display: grid;
            grid-template-columns: min-content 1fr;
            gap: ${theme.spacing.sm};
            align-items: center;
            font-size: ${theme.typography.textStyles.small.fontSize};
            background: ${theme.palette.background.light};
            padding: ${theme.spacing.sm};
            border-radius: ${theme.shape.borderRadius.default};
        `}
    `;

    const ProfileImage = styled.img.attrs(() => ({
        width: 30,
        height: 30,
        referrerPolicy: 'no-referrer',
    }))`
        ${({ theme }) => `
            border-radius: 15px;
            border: 2px solid ${theme.palette.border.dark};
            background; ${theme.color.N3};
        `}
    `;

    export function LoggedInUserInfo({ user }: { user: api.User }) {
        return (
            <UserInfo>
                <ProfileImage src={user.picture} alt={`Profile photo of ${user.name}`} />
                <strong>{user.name}</strong>
            </UserInfo>
        );
    }

    export const Column = styled.div`
        display: grid;
        align-items: center;
        margin-left: auto;
    `;
}

export default user;
